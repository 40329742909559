.from-warter-m4 {
  @apply flex xl:flex-row justify-between flex-col gap-4  my-[1.5rem] mb-8;
  
    .box-total-m {
      @apply bg-[#4D4294] text-white flex flex-col w-[16rem] max-xl:w-[260px] justify-center items-center rounded-md mx-auto;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
  }

  .box-main-4 {
    @apply flex flex-col w-full rounded-md p-4;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .button-text-4 {
      @apply flex w-full justify-between items-center;
    }

    .from-Date-filter1 {
      @apply grid grid-cols-3 max-xl:grid-cols-2 max-sm:grid-cols-1 items-start gap-4 mt-3 w-full;
      .date-1 {
        @apply flex w-full items-center gap-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-2 {
        @apply flex w-full items-center gap-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-3 {
        @apply flex w-full items-center gap-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-4 {
        @apply flex w-full items-center pl-24;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-5 {
        @apply flex w-full items-center gap-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-6 {
        @apply flex w-full items-center pl-10 max-sm:pl-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-7 {
        @apply flex justify-between w-full items-center pl-24 sm:col-span-2;
      }
    }

    .box-day555 {
      @apply  max-md:w-[120px] bg-rose-300;
    }
  }
}
.from-filter-2 {
  @apply flex xl:flex-row flex-col gap-4 w-full;
}
