.from-warter-m2 {
  @apply flex xl:flex-row justify-between flex-col gap-4  my-[1.5rem] mb-8;
  
    .box-total-m {
      @apply bg-[#4D4294] text-white flex flex-col w-[16rem] max-xl:w-[260px] justify-center items-center rounded-md mx-auto;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
  }

  .box-main-6 {
    @apply flex flex-col gap-4 w-full rounded-md p-4;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .button-text-6 {
      @apply flex w-full justify-between items-center;
    }

    .from-filter-6 {
      @apply flex xl:flex-row flex-col gap-4 w-full;

      // ส่วนซ้าย
      .from-Date-filter-1 {
        @apply flex flex-col w-full gap-4 max-xl:order-1;
        .date-1-m {
          @apply flex justify-start md:justify-between;
        }
        .date-2-m {
          @apply flex justify-start md:justify-between;
        }
        .date-3-m {
          @apply flex justify-start md:justify-between;
        }
      }

      // ส่วนกลาง
      .from-Date-filter-2 {
        @apply flex flex-col w-full justify-between gap-4 max-xl:order-3;
        .date-filter-11 {
          @apply flex w-full justify-start md:justify-between items-center;
        }
        .date-filter-2 {
          @apply flex w-full justify-start md:justify-between md:gap-2 gap-0 items-center;
        }
      }

      // ส่วนล่าง
      .from-Date-filter-3 {
        @apply flex flex-col w-full justify-between gap-4 max-xl:order-2;
        .date-filter-11 {
          @apply flex w-full justify-start md:justify-between items-center;
        }
        .date-filter-2 {
          @apply flex w-full justify-start md:justify-between md:gap-2 gap-0 items-center;
        }
      }

      .box-day555 {
        @apply max-md:w-[120px] bg-rose-300 ;
      }
    }
  }
}
