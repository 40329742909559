.box-graphC {
  @apply w-full overflow-x-auto;
  .css-idhtcv-MuiResponsiveChart-container{
    @apply max-sm:h-[350px] max-sm:w-[1000px] font-medium;
  }
}

.box-graph {
  @apply bg-white rounded-md p-4 w-full;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .box-graph-detail {
    @apply flex max-3xl:flex-col gap-y-6;
    .box-graphA {
      @apply w-[60%] flex gap-6 items-center max-sm:items-start font-medium max-3xl:order-2 max-lg:w-full max-sm:flex-col-reverse;
      p {
        @apply font-medium ;
      }

      .colorGraphs {
        @apply flex items-center gap-4 ;
        .color-graph {
          @apply flex items-center;
          p {
            @apply font-medium;
          }
        }
      }
    }

    .box-graphB {
      .img-graphB {
        @apply max-lg:hidden;
      }
      @apply w-full flex justify-between gap-4 items-center max-3xl:gap-y-2 max-3xl:gap-x-1 max-3xl:p-2 max-3xl:order-1 max-sm:grid max-sm:grid-cols-2;
      .total-graph {
        @apply w-[15%] flex flex-col max-sm:w-full border-[2px] border-r-[#D9D9D9] max-3xl:w-[20%] border-transparent;
        .title-total {
          @apply text-[16px] max-3xl:text-[15px] font-medium;
        }
        .price-total {
          @apply text-[16px] font-bold;
        }
      }
      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        @apply min-w-[100px] max-3xl:w-[100px] font-medium;
      }
    }
  }
}
