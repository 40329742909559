.custom-data-grid {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  // ปรับขนาดของตาราง
  &.MuiDataGrid-root {
    &--densityStandard {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
  }
  .MuiDataGrid-columnHeaders {
    background-color: #eff2f7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  // ปรับสไตล์ของแถว
  .css-1lymaxv-MuiDataGrid-root {
    // เพิ่มสีพื้นหลังแถว
    &-row {
      background-color: #ffffff;

      // ปรับสีของแถวเมื่อมีการโฮเวอร์
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
.MuiDataGrid-virtualScrollerRenderZone {
  padding-left: 20px;
  padding-right: 20px;
}

.custom-data-grid.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-1lymaxv-MuiDataGrid-root {
  @media (min-width: 3000px) {
    border-width: 1px;
  }

  @media (max-width: 3000px) {
    border-width: 0;
  }
}

.search {
  @apply flex items-center;
}

.button-h:hover {
  background-color: #8a7ae3 !important; /* เปลี่ยนสีเมื่อโฮเวอร์ */
}


.data-boxB{
  @apply flex my-[1.5rem] justify-between w-full mb-4 max-2xl:gap-2 gap-4 max-lg:grid max-lg:grid-cols-4 max-md:grid-cols-2 ;
  .total-Data{
  @apply flex gap-2 justify-between w-full bg-[#4D4294] text-white max-2xl:text-[14px] text-[16px] rounded-md p-2;
}
}