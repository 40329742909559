.header{
@apply flex justify-between;
  .searchE{
    @apply grid grid-cols-3 max-md:grid-cols-2 my-2 gap-4 max-md:w-full;
}
}

.data-box{
  @apply flex my-[1.5rem] justify-between w-full mb-4 max-2xl:gap-2 gap-4 max-lg:grid max-lg:grid-cols-3 max-md:grid-cols-2 ;
  .total-Data{
  @apply flex gap-2 justify-between w-full bg-[#4D4294] text-white max-2xl:text-[14px] text-[16px] rounded-md p-2;
}
}

.custom-data-grid {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  
    // ปรับขนาดของตาราง
    &.MuiDataGrid-root {
      &--densityStandard {
        @apply shadow-lg;
        border-radius: 10px;
      }
    }
    .MuiDataGrid-columnHeaders {
      background-color: #eff2f7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
    // ปรับสไตล์ของแถว
    .css-1lymaxv-MuiDataGrid-root {
      // เพิ่มสีพื้นหลังแถว
      &-row {
        background-color: #ffffff;
  
        // ปรับสีของแถวเมื่อมีการโฮเวอร์
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .custom-data-grid.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-1lymaxv-MuiDataGrid-root {
    @media (min-width: 3000px) {
      border-width: 1px;
    }
  
    @media (max-width: 3000px) {
      border-width: 0;
    }
  }
  
  .search {
    @apply flex items-center ;
  }

  .button-h:hover {
    background-color: #8a7ae3 !important; /* เปลี่ยนสีเมื่อโฮเวอร์ */
  }

  .width-modalE {
  @apply h-[80%] xl:w-[70%] w-[90%] overflow-y-auto relative;
  
}

.width-modalEditele {
  @apply h-[50%] xl:w-[70%] w-[90%] overflow-y-auto relative;
  
}

// .swal2-container {
//   z-index: 2000 !important; /* ตั้งค่าให้สูงกว่า Modal */
// }