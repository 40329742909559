.main-content {
  @apply flex 2xl:flex-row w-full flex-col gap-12 2xl:gap-6 justify-between mt-6;
  .main-map {
    @apply flex flex-col md:flex-row 2xl:flex-col 2xl:w-[500px] w-full gap-12 2xl:gap-6;
    .test {
      @apply flex flex-col-reverse 2xl:flex-col gap-6 w-full;
      .map {
        @apply bg-gray-100 rounded-lg hover:bg-gray-200 cursor-pointer object-cover;
        .img {
          @apply object-cover w-full h-full;
        }
      }

      .main-color {
        @apply flex flex-row w-full text-[14px] 2xl:justify-center sm:justify-start justify-center items-start gap-4;
        .flex-box-1 {
          @apply flex sm:flex-row flex-col items-center gap-2;
          .color-1 {
            width: 13px;
            height: 13px;
            background-color: #ffce72;
            border-radius: 3px;
          }
        }
        .flex-box-2 {
          @apply flex sm:flex-row flex-col items-center gap-2;
          .color-2 {
            width: 13px;
            height: 13px;
            background-color: #3f8cff;
            border-radius: 3px;
          }
        }
        .flex-box-3 {
          @apply flex sm:flex-row flex-col items-center gap-2;
          .color-3 {
            width: 13px;
            height: 13px;
            background-color: #ff9cbd;
            border-radius: 3px;
          }
        }
        .flex-box-4 {
          @apply flex sm:flex-row flex-col items-center gap-2;
          .color-4 {
            width: 13px;
            height: 13px;
            background-color: #7cb977;
            border-radius: 3px;
          }
        }
      }
    }
    .main-block {
      @apply w-full rounded-md sm:p-4 p-2;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .zone {
        @apply flex w-full items-center justify-between;
      }
      .select-zone {
        @apply mt-6 mb-6;
        .Color-status {
          @apply flex;
        }
        .BlockDetails {
          @apply flex justify-between items-center;
        }
        .name-block {
          @apply grid justify-between lg:grid-cols-7 md:grid-cols-5 grid-cols-7 2xl:h-full md:h-[300px] h-full overflow-y-auto p-3 mt-2 gap-3;
          .block {
            @apply w-[35px] h-[35px] border-[1.6px] border-[#a5a5a5] text-[#777777] flex items-center justify-center cursor-pointer rounded-md text-sm font-medium p-1;
          }
        }
      }
    }
  }

  .main-map {
    @media (min-width: 1600px) {
      width: 700px;
    }
  }

  // main-from
  .main-from {
    @apply flex flex-col w-full;
    .from-box2 {
      @apply flex gap-4;
    }
    .box-from {
      @apply w-full rounded-lg p-4;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .from-information {
        @apply grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-8 w-full mt-6 mb-12;
        .text-from1 {
          @apply flex gap-4;
        }
        .text-from2 {
          @apply flex gap-4;
        }
        .text-from3 {
          @apply flex gap-4;
        }
        .text-from4 {
          @apply flex gap-4;
        }
      }
    }
  }
  .border {
    @apply border-gray-300 border-b-2 border-dashed;
  }

  .button {
    @apply flex justify-center;
  }

  // box-shop
  .box-shop-add {
    @apply w-full rounded-lg p-4 mt-8;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .herder-shop-add {
      @apply flex w-full items-center justify-between;
    }
    .from-shop-add {
      @apply gap-[4.5rem] flex sm:flex-row flex-col justify-between  mt-6;
      .figure-container-add {
        @apply flex sm:flex-row flex-col mt-6 sm:mt-0 justify-between items-center gap-6 sm:gap-12;
        .figure-add {
          @apply flex justify-center items-center w-[140px] h-[140px]  rounded-lg border-dashed border-2 border-gray-300 p-4;
        }
        .figure-text {
          text-align: center;
        }
        .content-images-add {
          max-width: 100%;
          max-height: 200px;
        }
      }
      .text-from-shop {
        @apply flex flex-col h-auto w-full gap-8;
      }
    }

    .from-information {
      @apply mt-8;
      .from-box-information {
        @apply flex sm:flex-row flex-col justify-between gap-4 mt-6;
        .from-1 {
          @apply flex flex-col w-full gap-8;
        }
        .from-2 {
          @apply flex flex-col w-full gap-8;
        }
        .from-3 {
          @apply flex flex-col w-full gap-8;
          .from3-1 {
            @apply flex w-full gap-4;
          }
          .from3-2 {
            @apply flex w-full gap-4;
          }
        }
      }
    }
  }
  .Button-shop {
    @apply flex sm:justify-start justify-center gap-4 mt-10 mb-12;
  }
}

.width-modalRe {
  @apply w-[40%] max-md:h-[80%] max-2xl:w-[80%] h-auto max-md:w-[90%] overflow-y-auto;

  .header-invoice {
    @apply grid grid-cols-1 text-center border-b-2 border-dashed py-4 max-md:grid-cols-1 max-md:gap-y-4;
    .header1 {
      @apply text-[2rem] py-1 max-md:flex-col;
    }
    .header2 {
      @apply text-[1rem] py-1 grid grid-cols-2;
    }
  }
  .detail-invoice {
    @apply border-b-2 border-dashed py-4;
    .detail1 {
      @apply flex justify-between text-[1rem] py-1 pl-6;
      .detail-text {
        @apply text-3xl font-[400] p-0;
      }
      .detail-text1 {
        @apply text-3xl pl-2;
      }
    }
  }
  .total-invoice {
    @apply border-b-2 border-dashed py-4;
    .total1 {
      @apply flex text-3xl justify-between;
      .total-text {
        @apply text-3xl font-[400] p-0;
      }
    }
  }

  .note-invoice {
    .note-text {
      @apply text-3xl font-[400] pt-4;
    }
  }
}

.width-boxM {
  @apply w-[50%] flex flex-col justify-start;
  .allType {
    @apply flex flex-col gap-4;
    .title-box {
      @apply flex justify-between items-center;
      p{
        @apply text-[20px] font-medium;
      }
      .CancelIconPa1 {
        @apply flex justify-end;
        .CancelIconPa:hover {
          transform: scale(1.1);
          transition: transform 0.3s ease;
          color: red;
        }
      }
    }
    .colorType {
      @apply grid grid-cols-5 gap-2;

      .flex-box-1 {
        @apply flex gap-2 items-center;
        p {
          @apply text-[16px] items-center;
        }
      }
    }
  }
}

.btn-other {
  @apply flex sm:flex-row flex-col items-center gap-2;
}
.btn-other:hover {
  @apply underline decoration-2 decoration-[#6c5dd3] underline-offset-4;
}
