.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 10px !important;
}

.defective-nav {
  @apply max-sm:flex-col max-sm:justify-start;
  .add-box {
    @apply w-full flex justify-end max-sm:justify-start;
  }
}

.box-form {
  @apply bg-white p-8 mb-4 ;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  p {
    @apply text-[14px] font-[500] mb-4;
  }
  .first-row {
    @apply flex justify-start flex-col gap-2 w-full mb-2 ;
  }
 
}
 .box-btn {
    @apply flex gap-x-3 ;
  }