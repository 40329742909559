.nav1 {
  @apply flex md:flex-row xl:justify-between flex-col justify-center  pb-4;
}
.from-warter1 {
  @apply flex xl:flex-row justify-between flex-col gap-4 mb-8;
  .box-total-m {
    @apply bg-[#4D4294] text-white flex flex-col w-[16rem] max-xl:w-[260px] justify-center items-center rounded-md mx-auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  //  บล็อกด้านขวา
  .from-filter1 {
    @apply flex flex-col w-full rounded-md max-xl:order-3 p-4;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .button-text {
      @apply flex max-xl:flex-col w-full justify-between items-center;
      .p-salary {
        @apply flex justify-start max-xl:w-full;
      }
      .button-salary {
        @apply lg:flex-row flex-col justify-end w-full;
      }
    }
    .from-Date-filter1 {
      @apply grid grid-cols-4 max-xl:grid-cols-2 max-sm:grid-cols-1 items-start gap-4 mt-3 w-full;
      .date-1 {
        @apply flex w-full items-center mt-2 gap-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-2 {
        @apply flex w-full items-center mt-2 gap-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-3 {
        @apply flex w-full items-center mt-2 pl-24;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-4 {
        @apply flex w-full items-center mt-2 pl-24;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-5 {
        @apply flex w-full items-center mt-2  gap-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-6 {
        @apply flex w-full items-center mt-2 pl-10 max-sm:pl-2;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .date-7 {
        @apply flex w-full items-center mt-2 pl-24 max-2xl:pl-0;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
    }
  }
}

// button edit,delete
.edit-button,
.delete-button {
  border: none;
  background: none;
  cursor: pointer;

  img {
    transition: transform 0.3s;
  }

  &:hover img {
    transform: scale(1.2); // เพิ่มขนาดซูม 20% เมื่อฮัวเวอร์ที่ปุ่ม
  }
}

// @media (max-width: 1280px) {
//   .MuiFormControl-root {
//     &.MuiTextField-root {
//       width: 71%;
//     }
//   }
// }

@media (max-width: 640px) {
  .date-2-text {
    margin-left: 31px;
  }
}

.from-shopSalary {
  @apply gap-[4.5rem] flex flex-row justify-between  mt-6 max-lg:flex-col;
  .figure-container {
    @apply flex flex-row  max-lg:flex-col mt-6 sm:mt-0 justify-between items-center gap-6  max-lg:gap-4;
    .figure {
      @apply flex justify-center items-center w-[160px] h-[160px]  rounded-lg border-dashed border-2 border-gray-300 p-4;
    }
    .figure-text {
      text-align: center;
    }
    .content-images {
      max-width: 100%;
      max-height: 200px;
    }
  }
  .text-from-shop {
    @apply flex flex-col h-auto w-full gap-8;
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
  }
  .text-from-shop1 {
    @apply grid grid-cols-2 max-md:grid-cols-1 h-auto w-full gap-8;
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
  }
}

.from-Salary {
  @apply my-8;
  .from-box-information {
    @apply flex sm:flex-row flex-col justify-between gap-4 mt-6;
    .from-1 {
      @apply flex flex-col w-full gap-8;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .from-2 {
      @apply flex flex-col w-full gap-8;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
  }
}

.payment-status {
  @apply flex gap-4 mt-12 mb-4 md:flex-row flex-col;
  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
}

.width-modal1,.width-modal2 {
  @apply h-[93%] xl:w-[60%] w-[90%] overflow-y-auto relative;
}

.CancelIcon {
  @apply absolute top-5 right-5;
}

.box-detailS {
  @apply w-full h-auto flex justify-between gap-4 relative mb-8 p-8 rounded-xl bg-gradient-to-r from-white to-purple-200 max-lg:grid max-lg:grid-cols-2 max-3xl:p-4 max-lg:gap-2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .img-boxr {
    @apply flex-initial w-[120px] h-[120px] max-2xl:w-[100px] max-2xl:h-[100px] mx-auto;

    .img-storer {
      @apply w-full h-full;
      border-radius: 8px;
    }
  }

  .box-Br {
    @apply bg-white  flex flex-col flex-initial w-[20%]  p-4 gap-y-4 items-start max-lg:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .date-start {
      @apply text-[30px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px]  max-lg:text-[25px] max-sm:text-base;
    }
  }

  .box-Cr {
    @apply bg-white  flex flex-col flex-initial w-[20%]  p-4 gap-y-4 items-start max-lg:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .deposit {
      @apply text-[30px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px] max-lg:text-[25px] max-sm:text-base;
    }
  }

  .box-Dr {
    @apply bg-white  flex flex-col flex-initial w-[20%]  p-4 gap-y-4 items-start max-lg:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .rent {
      @apply text-[30px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px] max-lg:text-[25px] max-sm:text-base;
    }
  }

  .box-Er {
    @apply bg-white flex flex-col flex-initial w-[30%] p-4 gap-y-4 items-start max-lg:w-full max-xl:col-span-2 max-lg:order-2 max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .NameShop {
      @apply text-[30px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px] max-lg:text-[25px] max-sm:text-base;
    }
  }
}
