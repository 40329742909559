.from-warter {
  @apply flex xl:flex-row flex-col gap-4 justify-between mb-8;

  //  บล็อกด้านซ้าย
  .from-save {
    @apply flex flex-col xl:w-[760px] w-full max-xl:order-2 rounded-md p-4;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .from-Date {
      @apply flex justify-between items-center gap-4 mt-4;
    }
    .service-fee {
      @apply flex items-center gap-4 mt-6;
    }
  }

  //  บล็อกกลาง
  .box-layout {
    @apply flex justify-center;
    .box-total {
      @apply bg-[#4D4294] text-white flex flex-col w-64 max-xl:w-[260px] justify-center items-center rounded-md p-4;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  //  บล็อกด้านขวา
  .from-filter {
    @apply flex flex-col w-full rounded-md max-xl:order-3 p-4;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .button-text {
      @apply flex w-full justify-between;
    }
    .from-Date-filter {
      @apply flex sm:flex-row flex-col  items-center gap-4 mt-3;
      .date-1 {
        @apply flex w-full justify-between items-center mt-2;
      }
      .date-2 {
        @apply flex w-full justify-between items-center mt-2;
        .box-year {
          @apply max-sm:w-[107px] p-0;
        }
      }
    }
  }
}

// button edit,delete
.edit-button,
.delete-button {
  border: none;
  background: none;
  cursor: pointer;

  img {
    transition: transform 0.3s;
  }

  &:hover img {
    transform: scale(1.2); // เพิ่มขนาดซูม 20% เมื่อฮัวเวอร์ที่ปุ่ม
  }
}

// @media (max-width: 1280px) {
//   .MuiFormControl-root {
//     &.MuiTextField-root {
//       width: 71%;
//     }
//   }
// }

@media (max-width: 640px) {
  .date-2-text {
    margin-left: 31px;
  }
}
