.main-graphs {
  @apply flex flex-col w-full gap-6;
  .total-income {
    // @apply flex xl:flex-row flex-col w-full bg-red-200 gap-4;
    @apply flex xl:flex-row flex-col justify-between w-full gap-2;
    .graphs-total {
      @apply xl:w-[49.5%] w-full p-4 rounded-lg mb-6;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .barchart {
        @apply flex flex-col items-center w-full;
        .main-color {
          @apply flex w-full text-[14px] max-sm:grid max-sm:grid-cols-2 justify-center items-start gap-4 p-4 ;
          .flex-box-1 {
            @apply flex  items-center gap-2;
            .color-1 {
              width: 13px; 
              height: 13px;
              background-color: #6C5DD3;
              border-radius: 3px;
            }
          }
          .flex-box-2 {
            @apply flex items-center gap-2;
            .color-2 {
              width: 13px;
              height: 13px;
              background-color: #A0D7E8;
              border-radius: 3px;
            }
          }
          .flex-box-3 {
            @apply flex  items-center gap-2;
            .color-3 {
              width: 13px;
              height: 13px;
              background-color: #FFCE72;
              border-radius: 3px;
            }
          }
          .flex-box-4 {
            @apply flex  items-center gap-2;
            .color-4 {
              width: 13px;
              height: 13px;
              background-color: #898088;
              border-radius: 3px;
            }
          }
          .flex-box-5 {
            @apply flex items-center gap-2;
            .color-5 {
              width: 13px;
              height: 13px;
              background-color: #6edcff;
              border-radius: 3px;
            }
          }
          .flex-box-6 {
            @apply flex  items-center gap-2;
            .color-6 {
              width: 13px;
              height: 13px;
              background-color: #FF8D8D;
              border-radius: 3px;
            }
          }
          .flex-box-7 {
            @apply flex  items-center gap-2;
            .color-7 {
              width: 13px;
              height: 13px;
              background-color: #e695d4;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }

}

.income {
  @apply w-full p-4 rounded-lg ;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .income-rent {
    @apply grid grid-cols-4 max-xl:grid-cols-4 max-sm:grid-cols-2 text-center xl:gap-3 gap-4 mt-8 text-white;
    .rent {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #6C5DD3;
    }
    .tent-deposi {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #e695d4;
    }
    .water {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #A0D7E8;
    }
    .fiElectricityne {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #FFCE72;
    }
    .Fine {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #898088;
    }
    .ExpensesPerMonth {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #6edcff;
    }
    .Overdue {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #FF8D8D;
    }
    .Refund{
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #66f561;
    }
  }
}


.dropdow {
  @apply flex justify-between items-center gap-2 w-full mb-6 ;
}
