.custom-data-grid {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  // ปรับขนาดของตาราง
  &.MuiDataGrid-root {
    &--densityStandard {
      @apply shadow-lg;
      border-radius: 10px;
    }
  }
  .MuiDataGrid-columnHeaders {
    background-color: #eff2f7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  // ปรับสไตล์ของแถว
  .css-1lymaxv-MuiDataGrid-root {
    // เพิ่มสีพื้นหลังแถว
    &-row {
      background-color: #ffffff;

      // ปรับสีของแถวเมื่อมีการโฮเวอร์
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
.MuiDataGrid-virtualScrollerRenderZone {
  padding-left: 20px;
  padding-right: 20px;
}

.from-filter1 {
  @apply flex flex-col w-full rounded-md max-xl:order-3 p-4;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .button-text {
    @apply flex max-xl:flex-col w-full justify-between items-center;
    .p-salary {
      @apply flex justify-start max-xl:w-full;
    }
    .button-salary {
      @apply lg:flex-row flex-col justify-end w-full;
    }
  }

  .from-Date-filter-Ele {
    @apply grid grid-cols-3 max-xl:grid-cols-2 max-sm:grid-cols-1 items-start gap-4 mt-3 w-full;
    .date-1 {
      @apply flex w-full items-center mt-2 gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-2 {
      @apply flex w-full items-center mt-2 gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-3 {
      @apply flex w-full items-center mt-2 pl-12 max-2xl:pl-24;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-4 {
      @apply flex w-full items-center mt-2 gap-2 max-2xl:order-2 max-md:order-1;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-5 {
      @apply flex w-full items-center mt-2 pl-10 gap-4 max-2xl:order-2 max-2xl:pl-2 max-md:order-1 ;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-6 {
      @apply flex w-full items-center mt-2 pl-10 max-sm:pl-2 max-2xl:pl-0  max-2xl:order-1 max-md:order-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    // .date-7 {
    //   @apply flex w-full items-center mt-2 pl-24 max-2xl:pl-0 ;
    //   .MuiFormControl-root.MuiTextField-root {
    //     width: 100%;
    //   }
    // }
  }
}

.custom-data-grid.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-1lymaxv-MuiDataGrid-root {
  @media (min-width: 3000px) {
    border-width: 1px;
  }

  @media (max-width: 3000px) {
    border-width: 0;
  }
}

.search {
  @apply flex items-center;
}

.button-h:hover {
  background-color: #8a7ae3 !important; /* เปลี่ยนสีเมื่อโฮเวอร์ */
}

.data-box4 {
  @apply flex my-[1.5rem] justify-between w-full mb-4 max-2xl:gap-2 gap-4 max-sm:grid max-sm:grid-cols-2;
  .total-Data {
    @apply flex gap-2 justify-between w-full bg-[#4D4294] text-white max-2xl:text-[14px] text-[16px] rounded-md p-2;
  }
}
