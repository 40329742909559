
.box-modal-add {
.justifu-CancelIcon {
  @apply flex w-full justify-between;  
}

.main-modal {
    @apply flex md:flex-row flex-col justify-center gap-8 w-full mt-6;
    .box-modal-1 {
        @apply flex flex-col gap-4;
    }
    .box-modal-2 {
        @apply flex flex-col gap-4;
    }
}
}
.Button-save {
    @apply flex justify-center w-full mt-10;
}

.width-modal {
    @apply max-2xl:w-11/12;
}

.CancelIcon:hover {
    transform: scale(1.1); 
    transition: transform 0.3s ease; 
    color: red;
}