@import "./../../../variables";

// .menu-link.active,
// .sub-items.active {
//   border-color: #6c5dd3 !important;
//   pointer-events: none;
//   border-left: 3px solid;
// }

.menu-link.active {
  @apply ml-[0.75rem] mb-1.5 relative;
  .nav-menu {
    padding: 0rem;
  }
  .navlink {
    color: #ffffff !important;
    padding: 0rem 0rem;
    // width: 90%;
  }
  .white-image {
    filter: brightness(0) invert(1);
  }

  background-color: #6c5dd3;
  border-radius: 0.375rem;
  margin-left: 0rem;
}

.pink-btn {
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 3px 3px 0 #e91e6326, 0 3px 1px -2px #e91e6333,
    0 1px 5px 0 #e91e6326;
  &:hover {
    background-color: #e91e63 !important;
    border-color: #e91e63;
    box-shadow: 0 14px 26px -12px #e91e6366, 0 4px 23px 0 #e91e6326,
      0 8px 10px -5px #e91e6333;
  }
}
.aside {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 16rem !important;
  border-right: 2px solid #dfdfdf;

  // padding: 0.8rem;
  // background-image: linear-gradient(195deg,$bgDark 0%,$bgBrownDark 100%);
  background-color: #f7f7f7;
  z-index: 40;
  color: #000000;
  border-top-right-radius: 3px;
  transition-duration: 0.35s;

  nav {
    overflow-y: auto;
    height: calc(100vh - 58px);
    &::-webkit-scrollbar {
      width: 3px;
      margin-left: 1px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      outline: 0px solid #ffffff;
      background-color: #42424a;
    }

    .language-selection {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1rem;
      gap: 4px;
      .btn-lang {
        font-family: inherit;
        font-size: 12px;
        min-width: 0;
        width: 1rem;
        line-height: 1.5rem;
        height: 1.5rem;
        color: #6c757d;
        border-color: #6c757d;
        opacity: 0.75;
        font-weight: 400;
        &:hover {
          opacity: 1;
        }
        &.selected {
          color: #000000;
          border-color: #fff;
          opacity: 1;
          pointer-events: none;
        }
      }
    }

    .line-section {
      @apply mx-auto;
      background-image: linear-gradient(to right, #42424a, #42424a, #42424a);
      // background-image: #42424a;
      margin-bottom: 0.5rem;
      color: inherit;
      border: 0;
      opacity: 0.25;
      height: 2px;
      width: 80%;
      background-color: transparent;
      &.gap {
        margin: 1rem;
      }
    }
    .title-section {
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      padding-left: 1rem;
      margin-left: 0.5rem;
      margin-bottom: 0.25rem;
      display: flex;
    }
    .sidenav-header {
      transition-duration: 0.2s;
      margin: 1.25rem;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      user-select: none;
      .figure-image {
        max-width: 154px;
        max-height: 50px;
        margin: auto;
        .website-logo {
          width: 100%;
          height: 100%;
        }
      }
      .website-powerby {
        line-height: 20px;
        font-size: 16px;
        margin-left: auto;
        font-weight: 400;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        .sub-website {
          font-size: 20px;

          color: #000000;
          text-decoration: underline;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .sidenav-main {
      padding: 0.8rem;
    }
  }

  &:hover {
  }
}

ul.nav-menu {
  display: flex;
  flex-direction: column;

  .menu-link {
    // margin-bottom: 0.75rem;
    .navlink {
      color: rgb(0, 0, 0);
      text-decoration: none;
      cursor: pointer;
      padding: 0.5rem 1rem;
      // margin-left: 0.75rem;
      // margin-right: 0.75rem;
      margin-bottom: 1.5px;
      display: flex;
      border-radius: 0.375rem;
      gap: 2px;
      font-weight: 300;
      .menu-icon {
        width: 1.875rem;
        height: 1.875rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .menu-title {
        font-size: 16px;
        margin-left: 0.75rem;
        margin-top: auto;
        margin-bottom: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .menu-title-user {
          font-weight: 400;
        }
      }
      .user-role {
        font-size: 13px;
      }
      .user-toggle {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 0.75rem;
        margin-top: auto;
        margin-bottom: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        background-color: #6c5dd3;
        color: #fff;
        // margin-left: 0.75rem;
        // margin-right: 0.75rem;
        .white-image {
          filter: brightness(0) invert(1);
        }
      }
    }

    &.has-child {
      position: relative;
      user-select: none;

      .figure-image {
        border-radius: 100%;
        max-width: 40px;
        max-height: 40px;
        margin: auto;
        .user-pic {
          width: 100%;
          height: 100%;
        }
      }

      .toggle-submenu {
        position: absolute;
        right: 0.8rem;
        top: 1rem;
        width: 1rem;
        height: 1rem;
        transition-duration: 0.5s;
        transform: rotate(90deg);
      }
      &.opened {
        .toggle-submenu {
          transform: rotate(0deg);
          opacity: 0.5;
        }
      }

      .child-menu {
        max-height: 0;
        height: 100%;
        overflow: hidden;
        transition-duration: 1s;
        .nav-items {
          display: flex;
          flex-direction: column;
          gap: 2px;
          list-style-type: none;
          .sub-items {
            // border-left: 3px solid #9b9b9e;
            // margin-left: 2.5rem;
            .items {
              color: rgb(0, 0, 0);
              text-decoration: none;
              cursor: pointer;
              padding: 0.5rem 1rem 0.5rem 1.25rem;
              font-weight: 300;
              margin-left: 0.75rem;
              margin-right: 0.75rem;
              margin-bottom: 1.5px;
              display: flex;
              border-radius: 0.375rem;
              transition: all 0.3s ease-in-out;
              font-size: 14px;
              &:hover {
                background-color: #6c5dd3;
                // padding-left: 1.5rem;
                color: #fff;
              }
              .menu-icon {
                width: 1.875rem;
                height: 1.875rem;
              }
              .menu-title {
                margin-left: 0.75rem;
                margin-top: auto;
                margin-bottom: auto;
              }
            }
          }
        }
      }

      &.opened {
        .child-menu {
          max-height: 54vh;
        }
      }
    }
  }

  &.mini-bar {
    .menu-link {
      margin-bottom: 0.5rem;
      .navlink {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        background-image: linear-gradient(195deg, #ec407a 0%, #d81b60 100%);
        .menu-title {
          margin-left: auto;
          margin-right: auto;
        }
        &:hover {
          background-color: #6c5dd3;
          margin-left: 0.75rem;
          margin-right: 0.75rem;
          color: #fff;
        }
      }
    }
  }
}
.faIcon {
  min-width: 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #707070;
}

.navlink:hover .faIcon-items {
  display: none;
}

.footerLink {
  .faIcon {
    margin: auto 0.25rem auto auto;
  }
  .menu-title {
    margin-left: 0 !important;
  }
}
.collapsed {
  .footerLink {
    .faIcon {
      margin: auto;
    }
  }
}

.btn-collapse-menu {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  right: -1.75rem;
  top: 1rem;
  color: #42424a;
  &:hover {
    color: $bgBrownDark;
  }
  &:active {
    transform: scale(1.08);
  }

  @media screen and (min-width: $xl) {
    display: flex;
  }
}

.collapsed {
  .btn-collapse-menu {
    display: none !important;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.child-menu2 {
  animation: slideInRight 0.5s ease-out;
}

.line-section {
  @apply flex flex-col;
}
.navlink2 {
  @apply flex flex-col;
}

.nav-items2 {
  @apply absolute top-1 left-[4.5rem] p-2 w-[130px] rounded-md hover:bg-[#6c5dd3] bg-gray-200;
  .items2 {
    @apply flex justify-center font-medium text-black hover:text-white items-center gap-2;
  }

}

.hidden-logout {
  @apply max-2xl:hidden;
}

// .has-child-1 {
//   @apply bg-red-400;
// }
