.custom-data-grid {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #fff;
  
    // ปรับขนาดของตาราง
    &.MuiDataGrid-root {
      &--densityStandard {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 10px;
      }
    }
    .MuiDataGrid-columnHeaders {
      background-color: #eff2f7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
    // ปรับสไตล์ของแถว
    .css-1lymaxv-MuiDataGrid-root {
      // เพิ่มสีพื้นหลังแถว
      &-row {
        background-color: #ffffff;
  
        // ปรับสีของแถวเมื่อมีการโฮเวอร์
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .custom-data-grid.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-1lymaxv-MuiDataGrid-root {
    @media (min-width: 3000px) {
      border-width: 1px;
    }
  
    @media (max-width: 3000px) {
      border-width: 0;
    }
  }
  
  .search {
    @apply flex items-center ;
  }

  .zoomable-image:hover {
    transform: scale(1.1); 
  }

  .button-h:hover {
    background-color: #4d33dd !important; 
  }
  

  .box-shop1 {
    @apply w-full rounded-lg p-4 ;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .herder-shop-add {
      @apply flex w-full items-center justify-between;
    }
    .from-shop-add {
      @apply gap-[4.5rem] flex sm:flex-row flex-col justify-between  mt-6;
      .figure-container-add {
        @apply flex sm:flex-row flex-col mt-6 sm:mt-0 justify-between items-center gap-6 sm:gap-12;
        .figure-add {
          @apply flex justify-center items-center w-[140px] h-[140px]  rounded-lg border-dashed border-2 border-gray-300 p-4;
        }
        .figure-text {
          text-align: center;
        }
        .content-images-add {
          max-width: 100%;
          max-height: 200px;
        }
      }
      .text-from-shop {
        @apply flex flex-col h-auto w-full gap-8;
      }
    }

    .from-information {
      @apply mt-8;
      .from-box-information {
        @apply flex sm:flex-row flex-col justify-between gap-4 mt-6;
        .from-1 {
          @apply flex flex-col w-full gap-8;
        }
        .from-2 {
          @apply flex flex-col w-full gap-8;
        }
        .from-3 {
          @apply flex flex-col w-full gap-8;
          .from3-1 {
            @apply flex w-full gap-4;
          }
          .from3-2 {
            @apply flex w-full gap-4;
          }
        }
      }
    }
  }

  .width-modalS {
    @apply h-[90%] xl:w-[70%] w-[90%] overflow-y-auto relative;
  }