.MuiDataGrid-columnHeaders {
  background-color: #EFF2F7;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  // border: 0px;
}
// ปรับสไตล์ของแถว
.css-1lymaxv-MuiDataGrid-root {
  // เพิ่มสีพื้นหลังแถว
  &-row {
    background-color: #ffffff;

    // ปรับสีของแถวเมื่อมีการโฮเวอร์
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
