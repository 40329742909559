.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 10px !important;
}

.defective-nav {
  @apply max-sm:flex-col max-sm:justify-start;
  .add-box {
    @apply w-full flex justify-end max-sm:justify-start;
  }
}

.box-form1{
  @apply  p-8 my-4;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  p {
    font-size: 25px;
    font-weight: 500;
  }
  .first-row {
    @apply flex justify-start flex-col gap-2 mb-2;
  }
 
}
 .box-btn {
    @apply flex justify-end gap-x-4 p-8;
  }


  .color-selector {
    display: inline-block;
    margin: 0 auto;
    border-radius: 3px;
    position: relative;
    padding: 6px 32px 6px 10px;
    font-family: verdana;
    background: white;
    
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: black transparent transparent transparent;
      position: absolute;
      right: 10px;
      top: 17px;
    }
    
    .circle {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eee;
      margin-right: 10px;
    }
    
    .hidden {
      position: absolute;
      left: 0;
      opacity: 0;
    }
    
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .overlay {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 105px;
    transition: 1s;
    
    &.active {
      top: calc(-50% + 105px);
      width: 160%;
      height: 160%;
      left: -30%;
    }
  }

  .button-Delete:hover{
    background-color: #e72626 !important
  }
  
  .button-Edit:hover{
    background-color: #FFAF00 !important
  }
  .width-modal-block {
    @apply max-2xl:w-11/12 p-4;
}