// ส่วนบน

.product-box {
  @apply 2xl:flex grid md:grid-cols-2 items-center gap-6 w-full h-auto py-8 px-8 rounded-lg bg-gradient-to-r from-white to-purple-200;
  box-shadow: 0px 2px 8px rgba(99, 99, 99, 0.2);

  .box-number {
    @apply 2xl:border-r-2 border-r-0 pr-12;
    font-size: 45px;
    font-weight: 600;
    .size-box {
      font-size: 24px;
      font-weight: 400;
      color: #707070;
    }
  }
  .product-images {
    width: 86px;
    height: 86px;
    margin-left: auto;
    margin-right: auto;
    .img {
      @apply object-cover w-full rounded-md bg-white;
    }
  }
  @media screen and (min-width: 1600px) {
    /* เพิ่มเงื่อนไข max-width */
    .product-images {
      width: 100px;
      height: 100px;
    }
  }

  .box-1 {
    @apply h-[90px]  w-auto p-4 rounded-md bg-white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .p-1 {
      font-size: 14px;
      font-weight: 400;
      color: #707070;
    }
    .p-2 {
      font-size: 18px;
      font-weight: 500;
    }
    @media screen and (min-width: 1600px) {
      height: auto;
      .p-1 {
        font-size: 20px;
      }
      .p-2 {
        font-size: 28px;
      }
    }
  }
}

// from
.box-store-expiration1 {
  @apply bg-white grid 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 justify-between xl:gap-6 gap-4 p-4;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  .p1 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  @media (max-width: 1023px) {
    .p1 {
      font-size: 18px;
    }
  }
  .from-box1 {
    @apply flex max-md:flex-col justify-center items-center gap-4 mt-2;
  }
  .from-box2 {
    @apply flex max-md:flex-col gap-4 mt-2;
  }
  .from-box3 {
    @apply flex flex-col gap-4 mt-2;
    .Switch {
      @apply flex items-center border-gray-950 border-[1px] rounded-md;
    }
  }
  .from-box4 {
    @apply flex flex-col gap-4 mt-2;
  }
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.content-lefts {
  @apply flex flex-col justify-center items-center w-full min-h-[293px] border-2 border-gray-200 rounded-md;
  .content-images {
    @apply w-full h-full object-cover p-2;
  }
  .figure-text {
    @apply flex flex-col w-full items-center;
  }
}

// button

.status-button {
  @apply flex gap-4 justify-center xl:justify-end w-full mt-8 mb-8;
}
.button-h1:hover {
  background-color: #e11d48 !important; /* เปลี่ยนสีเมื่อโฮเวอร์ */
}

.total-billingcycle {
  @apply flex max-md:flex-col w-full;
  .block-total {
    @apply flex justify-between items-center w-[50%] max-md:w-full max-md:mt-4 bg-[#6c5dd3] p-3 rounded-md text-[#fff] max-xl:text-sm text-lg ;
  }
}
