.custom-data-grid {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  
    // ปรับขนาดของตาราง
    &.MuiDataGrid-root {
      &--densityStandard {
        @apply shadow-lg;
        border-radius: 10px;
      }
    }
    .MuiDataGrid-columnHeaders {
      background-color: #eff2f7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
    // ปรับสไตล์ของแถว
    .css-1lymaxv-MuiDataGrid-root {
      // เพิ่มสีพื้นหลังแถว
      &-row {
        background-color: #ffffff;
  
        // ปรับสีของแถวเมื่อมีการโฮเวอร์
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .custom-data-grid.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-1lymaxv-MuiDataGrid-root {
    @media (min-width: 3000px) {
      border-width: 1px;
    }
  
    @media (max-width: 3000px) {
      border-width: 0;
    }
  }
  
  .search {
    @apply flex items-center ;
  }

  .button-h:hover {
    background-color: #8a7ae3 !important; /* เปลี่ยนสีเมื่อโฮเวอร์ */
  }

  .button-d:hover {
    background-color: #AE445A !important; /* เปลี่ยนสีเมื่อโฮเวอร์ */
  }

  .nav-AllStore {
    @apply flex md:flex-row xl:justify-between flex-col justify-center  pb-4;
  }
  .from-AllStore{
    @apply flex xl:flex-row justify-between flex-col gap-4  my-[1.5rem] mb-8;
  
    //  บล็อกด้านขวา
    .from-filter1 {
      @apply flex flex-col w-full rounded-md max-xl:order-3 p-4;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .button-text {
        @apply flex w-full justify-between items-center;
      }
      .from-Date-filter1 {
        @apply grid grid-cols-3 max-xl:grid-cols-2 max-sm:grid-cols-1 items-start gap-4 mt-3 w-full;
        .date-1 {
          @apply flex w-full items-center mt-2 gap-2;
          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
          }
        }
        .date-2 {
          @apply flex w-full items-center mt-2 gap-2;
          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
          }
        }
        .date-3 {
          @apply flex w-full items-center mt-2 gap-2 ;
          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
          }
        }
        .date-4 {
          @apply flex w-full items-center mt-2 pl-24;
          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
          }
        }
        .date-5 {
          @apply flex w-full items-center mt-2 gap-2;
          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
          }
        }
        .date-6 {
          @apply flex w-full items-center mt-2 pl-10 max-sm:pl-2;
          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
          }
        }
        .date-7 {
          @apply flex w-full items-center mt-2 pl-24 col-span-2;

          .flex-radio{
            @apply flex ;
          }
        }
      }
    }
  }
  
  // button edit,delete
  .edit-button,
  .delete-button {
    border: none;
    background: none;
    cursor: pointer;
  
    img {
      transition: transform 0.3s;
    }
  
    &:hover img {
      transform: scale(1.2); // เพิ่มขนาดซูม 20% เมื่อฮัวเวอร์ที่ปุ่ม
    }
  }
  
  // @media (max-width: 1280px) {
  //   .MuiFormControl-root {
  //     &.MuiTextField-root {
  //       width: 71%;
  //     }
  //   }
  // }
  
  @media (max-width: 640px) {
    .date-2-text {
      margin-left: 31px;
    }
  }
  
  .box-shop {
    @apply h-auto overflow-y-auto mx-4;
  }
  .from-shopSalary {
    @apply gap-[4.5rem] flex flex-row justify-between  mt-6 max-lg:flex-col;
    .figure-container {
      @apply flex sm:flex-row flex-col mt-6 sm:mt-0 justify-between items-center gap-6 sm:gap-12;
      .figure {
        @apply flex justify-center items-center w-[140px] h-[140px]  rounded-lg border-dashed border-2 border-gray-300 p-4;
      }
      .figure-text {
        text-align: center;
      }
      .content-images {
        max-width: 100%;
        max-height: 200px;
      }
    }
    .text-from-shop {
      @apply flex flex-col h-auto w-full gap-8;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
  }
  
  .from-Salary {
    @apply my-8;
    .from-box-information {
      @apply flex sm:flex-row flex-col justify-between gap-4 mt-6;
      .from-1 {
        @apply flex flex-col w-full gap-8;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
      .from-2 {
        @apply flex flex-col w-full gap-8;
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
        }
      }
    }
  }
  
  .payment-status {
    @apply flex gap-4 mt-12 mb-4 md:flex-row flex-col;
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
  }
  
  .width-modal {
    @apply max-md:w-11/12;
  }
  
  .data-box1{
    @apply flex my-[1.5rem] justify-between w-full mb-4 max-2xl:gap-2 gap-4 max-2xl:grid max-2xl:grid-cols-3 max-md:grid-cols-2 ;
    .total-Data{
    @apply flex gap-2 justify-between w-full bg-[#4D4294] text-white max-2xl:text-[14px] text-[16px] rounded-md p-2;
  }
  }