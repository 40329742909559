// ส่วนบน
.header {
  @apply mb-6 flex justify-between;
  .title-header {
    font-size: 2rem;
  }

  @media (max-width: 767px) {
    .title-header {
      font-size: 1.5rem;
    }
  }

  .box-buttonE {
    @apply flex gap-8 justify-center xl:justify-end mt-12;
  }
}
.product-box {
  @apply 2xl:flex grid md:grid-cols-2 items-center gap-6 w-full h-auto py-8 px-8 rounded-lg bg-gradient-to-r from-white to-purple-200;
  box-shadow: 0px 2px 8px rgba(99, 99, 99, 0.2);

  .box-number {
    @apply 2xl:border-r-2 border-r-0 pr-12;
    font-size: 45px;
    font-weight: 600;
    .size-box {
      font-size: 20px;
      font-weight: 400;
      color: #707070;
    }
  }
  .product-images {
    width: 86px;
    height: 86px;
    margin-left: auto;
    margin-right: auto;
    .img {
      @apply object-cover w-full rounded-md bg-white;
    }
  }
  @media screen and (min-width: 1600px) {
    /* เพิ่มเงื่อนไข max-width */
    .product-images {
      width: 100px;
      height: 100px;
    }
  }

  .box-1 {
    @apply h-[90px]  w-auto p-4 rounded-md bg-white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .p-1 {
      font-size: 14px;
      font-weight: 400;
      color: #707070;
    }
    .p-2 {
      font-size: 18px;
      font-weight: 500;
    }
    @media screen and (min-width: 1600px) {
      @apply h-auto;
      .p-1 {
        font-size: 20px;
      }
      .p-2 {
        font-size: 20px;
      }
    }
  }
}

// from
.box-store-expiration {
  @apply bg-white flex flex-col max-md:flex-wrap justify-between  gap-4 max-2xl:gap-8 p-4;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  .p1 {
    font-size: 20px;
    font-weight: 500;
  }

  @media (max-width: 1023px) {
    .max-lg\:p1 {
      font-size: 18px;
    }
  }
  .from-box1 {
    @apply grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-1 gap-4 mt-4;
    .rental-box {
      @apply flex flex-col;
    }
  }
  .from-box2 {
    @apply grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-1 gap-4 mt-4;
  }
  .from-box3 {
    @apply grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-1 gap-4 mt-4;
  }
  .from-box4 {
    @apply grid grid-cols-2 max-md:grid-cols-1 gap-4 mt-4 max-md:flex-wrap;
    .flex-box {
      @apply flex flex-col gap-4;
    }
  }
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.content-lefts {
  @apply flex flex-col justify-center items-center w-full min-h-[293px] border-2 border-gray-200 rounded-md;
  .content-images {
    @apply w-full h-full object-cover p-2;
  }
  .figure-text {
    @apply flex flex-col w-full items-center;
  }
}

.content-lefts.error{
  @apply border-red-500
}
// button

.status-buttonE {
  @apply flex gap-8 justify-center xl:justify-end w-full mt-8;
}

.width-modal22 {
  @apply w-[40%] max-md:h-[80%] max-2xl:w-[80%] h-[95%] max-md:w-[90%] overflow-y-auto;

  .header-invoiceEE {
    @apply border-b-2 border-dashed py-4 flex flex-col;
    .header11 {
      @apply flex flex-col text-center text-[2rem] pt-1  max-md:grid-cols-1 max-md:gap-y-2 w-full;
    }
    .header22 {
      @apply text-[1rem] pb-1 flex flex-col max-md:mt-2 max-md:flex-wrap;
    }
  }
  .detail-invoice1 {
    @apply border-b-2 border-dashed py-4;
    .detail1 {
      @apply flex justify-between text-[2rem] py-1 w-full;
      .detail-text {
        @apply text-[2rem] font-[400] p-0;
      }
      .detail-text1 {
        @apply text-[2rem] pl-2;
      }
    }
  }
  .total-invoice {
    @apply border-b-2 border-dashed py-4;
    .total1 {
      @apply flex  text-[2rem] justify-between;
      .total-text {
        @apply text-[2rem] font-[400] p-0;
      }
    }
  }

  .note-invoice {
    .note-text {
      @apply text-[1rem] font-[400] pt-4;
    }
  }
}

#print {
  @apply p-8;
}

.website-logo {
  @apply w-32;
}

.box-detailE {
  @apply w-full h-auto flex justify-between gap-4 relative p-4 rounded-xl bg-gradient-to-r from-white to-purple-200 max-3xl:grid max-3xl:grid-cols-2 max-3xl:p-4 max-lg:gap-2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .img-boxr {
    @apply flex-initial w-[120px] h-[120px] max-2xl:w-[100px] max-2xl:h-[100px] mx-auto;

    .img-storer {
      @apply w-full h-full;
      border-radius: 8px;
    }
  }

  .box-Ar {
    @apply flex flex-col flex-initial w-[10%] items-center max-3xl:w-full justify-center;
    border-right: 2px solid #d9d9d9;
    .number-block {
      @apply w-full grid grid-cols-2 max-3xl:flex justify-center items-center gap-4 max-md:flex-wrap max-md:gap-2 pr-2;
      .number {
        font-size: 30px;
        font-weight: 600;
      }

      @media (max-width: 1535px) {
        .number {
          font-size: 30px;
        }
      }

      @media (max-width: 1279px) {
        .number {
          font-size: 25px;
        }
      }
    }

    .size {
      @apply text-[18px] text-[#707070] font-[400]  max-sm:text-base;
    }
  }

  .box-Br {
    @apply bg-white  flex flex-col flex-initial w-[14%]  p-4 gap-y-4 max-3xl:gap-y-1 items-start max-3xl:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .date-start {
      @apply text-[25px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px]  max-lg:text-[20px] max-sm:text-base;
    }
  }

  .box-Cr {
    @apply bg-white  flex flex-col flex-initial w-[10%]  p-4 gap-y-4 max-3xl:gap-y-1 items-start max-3xl:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .deposit {
      @apply text-[25px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px] max-lg:text-[25px] max-sm:text-base;
    }
  }

  .box-Dr {
    @apply bg-white  flex flex-col flex-initial w-[10%]  p-4 gap-y-4 max-3xl:gap-y-1 items-start max-3xl:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .rent {
      @apply text-[25px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px] max-lg:text-[25px] max-sm:text-base;
    }
  }

  .box-Er {
    @apply bg-white flex flex-col flex-initial w-[30%] p-4 gap-y-4 max-3xl:gap-y-1 items-start max-3xl:w-full max-3xl:col-span-2 max-3xl:order-2 max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .NameShop {
      @apply text-[25px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px] max-lg:text-[25px] max-sm:text-base;
    }
  }

  .box-Fr {
    @apply bg-white flex flex-col flex-initial w-[10%] p-4 gap-y-4 max-3xl:gap-y-1 items-start max-3xl:w-full max-3xl:order-1 max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .type-business {
      @apply flex gap-2 items-center;
      .NameShop {
        @apply text-[25px] font-[600] max-3xl:text-[22px] max-2xl:text-[18px] max-lg:text-[25px] max-sm:text-base;
      }
    }
  }
}

.swal2-container {
  z-index: 2000 !important; /* ตั้งค่าให้สูงกว่า Modal */
}
