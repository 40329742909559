.box-detail {
  @apply w-full h-auto flex justify-between gap-4 max-2xl:gap-2 max-2xl:p-3 relative mb-4 p-8 rounded-xl bg-gradient-to-r from-white to-purple-200 max-lg:grid max-lg:grid-cols-2 max-3xl:p-4 max-lg:gap-2 ;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .img-boxr {
    @apply flex-initial w-[120px] h-[120px] max-2xl:w-[90px] max-2xl:h-[90px] mx-auto;

    .img-storer {
      @apply w-full h-full;
      border-radius: 8px;
    }
  }

  .box-Ar {
    @apply flex flex-col flex-initial w-[10%] items-center max-lg:w-full justify-center ;
    border-right: 2px solid #D9D9D9;
    ;
      .number {
        @apply text-[60px] font-[600] max-3xl:text-[40px] max-2xl:text-[30px] max-sm:text-6xl;
      }
      .size {
        @apply text-[20px] text-[#707070] font-[400]  max-sm:text-base;
      }
    
  }

  .box-Br {
    @apply bg-white  flex flex-col flex-initial w-[14%]  p-4 gap-y-4 items-start max-lg:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .date-start {
      @apply  font-[600] max-3xl:text-[22px] max-2xl:text-[18px]  text-[25px] max-sm:text-base;
    }
  }

  .box-Cr {
    @apply bg-white  flex flex-col flex-initial w-[10%]  p-4 gap-y-4 items-start max-lg:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .deposit {
      @apply  font-[600] max-3xl:text-[22px] max-2xl:text-[18px] text-[25px] max-sm:text-base;
    }
  }

  .box-Dr {
    @apply bg-white  flex flex-col flex-initial w-[10%]  p-4 gap-y-4 items-start max-lg:w-full max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .rent {
      @apply  font-[600] max-3xl:text-[22px] max-2xl:text-[18px] text-[25px] max-sm:text-base;
    }
  }

  .box-Er {
    @apply bg-white flex flex-col flex-initial w-[20%] p-4 gap-y-4 items-start max-lg:w-full   max-xl:p-2 ;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .NameShop {
     @apply  font-[600] max-3xl:text-[22px] max-2xl:text-[18px] text-[25px] max-sm:text-base;
    }
  }

  .box-Fr {
    @apply bg-white flex flex-col flex-initial w-[25%] p-4 gap-y-4 items-start max-lg:w-full max-xl:col-span-2 max-xl:p-2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    .title {
      @apply font-[400] text-[#707070] mb-[2px] max-2xl:text-[14px] text-base;
    }
    .type-business {
      @apply flex gap-2 items-center;
      .NameShop {
        @apply  font-[600] max-3xl:text-[22px] max-2xl:text-[18px] text-[25px] max-sm:text-base;
        
      }
    }
  }
}

.box-button{
  @apply flex justify-between items-center ;
}

.css-j69h9q-MuiButtonBase-root-MuiButton-root:hover{
background-color: #6C5DD3;
}