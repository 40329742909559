// ส่วนบน

.product-box {
  @apply 2xl:flex grid md:grid-cols-2  items-center gap-6 w-full h-auto py-8 px-8 rounded-lg bg-gradient-to-r from-white to-purple-200;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .box-number {
    @apply 2xl:border-r-2 border-r-0 pr-12;
    font-size: 45px;
    font-weight: 600;
    .size-box {
      font-size: 24px;
      font-weight: 400;
      color: #707070;
    }
  }
  .product-images {
    width: 86px;
    height: 86px;
    margin-left: auto;
    margin-right: auto;
    .img {
      @apply object-cover w-full rounded-md bg-white;
    }
  }
  @media screen and (min-width: 1600px) {
    /* เพิ่มเงื่อนไข max-width */
    .product-images {
      width: 100px;
      height: 100px;
    }
  }

  .box-1 {
    @apply h-[90px] w-auto p-4 rounded-md bg-white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .p-1 {
      font-size: 14px;
      font-weight: 400;
      color: #707070;
    }
    .p-2 {
      font-size: 18px;
      font-weight: 500;
    }
    @media screen and (min-width: 1600px) {
      height: auto;
      .p-1 {
        font-size: 20px;
      }
      .p-2 {
        font-size: 28px;
      }
    }
  }
}

// from

.box-store-data {
  @apply w-full rounded-lg mt-8 py-6 px-8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .store-information {
    @apply flex md:flex-row flex-col justify-between gap-4 items-center;
    font-size: 20px;
    font-weight: 500;
  }
  @media screen and (min-width: 1600px) {
    .store-information {
      font-size: 1.75rem;
    }
  }
  .from-box-data {
    @apply grid xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 justify-items-center gap-6 xl:gap-x-4 mt-6;
  }
  .flex-textarea{
    @apply grid xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-items-center gap-6 xl:gap-x-4 mt-6;

  }
  @media screen and (min-width: 1600px) {
    .from-box-data {
      @apply grid grid-cols-5;
    }
  }
}

// สถานะบล็อก

.block-status {
  @apply w-full rounded-lg mt-8 py-6 px-8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .block-h1 {
    font-size: 20px;
    font-weight: 500;
  }
  .flex-status {
    @apply flex md:flex-row flex-col items-start md:items-center md:gap-6 gap-2 mt-6;
    .flex-status-h1 {
      font-size: 16px;
      font-weight: 400;
      color: #00d715;
    }
    .flex-status-p {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

// button

.status-button {
  @apply flex gap-8 justify-center xl:justify-start w-full mt-12 mb-8;
}
