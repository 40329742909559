.search-box {
  @apply flex flex-col w-full p-4 rounded-lg mb-6;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .from-Date-filter1 {
    @apply grid grid-cols-3 max-xl:grid-cols-2 max-sm:grid-cols-1 items-start gap-4 mt-3 w-full;
    .date-1 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-2 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-3 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-4 {
      @apply flex w-full items-center pl-24;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-5 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-6 {
      @apply flex w-full items-center pl-10 max-sm:pl-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-7 {
      @apply flex  w-full items-center;
    }
  }
  .from-Date-filter2 {
    @apply grid grid-cols-2 max-sm:grid-cols-1 items-start gap-4 mt-3 w-full;
    .date-1 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-2 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-3 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-4 {
      @apply flex w-full items-center pl-24;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-5 {
      @apply flex w-full items-center gap-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-6 {
      @apply flex w-full items-center pl-10 max-sm:pl-2;
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    .date-7 {
      @apply flex  w-full items-center;
    }
  }
}

.main-graphs1 {
  @apply flex flex-col w-full gap-6;
  .graphs-total {
    @apply w-full p-4 rounded-lg;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .barchart {
      @apply flex flex-col items-center w-full;
      .main-color {
        @apply flex w-full text-[14px] max-sm:grid  max-sm:grid-cols-2 justify-center items-start gap-4 p-4;
        .flex-box-1 {
          @apply flex  items-center gap-2;
          .color-1 {
            width: 13px;
            height: 13px;
            background-color: #6c5dd3;
            border-radius: 3px;
          }
        }
        .flex-box-2 {
          @apply flex items-center gap-2;
          .color-2 {
            width: 13px;
            height: 13px;
            background-color: #a0d7e8;
            border-radius: 3px;
          }
        }
        .flex-box-3 {
          @apply flex  items-center gap-2;
          .color-3 {
            width: 13px;
            height: 13px;
            background-color: #ffce72;
            border-radius: 3px;
          }
        }
        .flex-box-4 {
          @apply flex  items-center gap-2;
          .color-4 {
            width: 13px;
            height: 13px;
            background-color: #898088;
            border-radius: 3px;
          }
        }
        .flex-box-5 {
          @apply flex items-center gap-2;
          .color-5 {
            width: 13px;
            height: 13px;
            background-color: #6edcff;
            border-radius: 3px;
          }
        }
        .flex-box-6 {
          @apply flex  items-center gap-2;
          .color-6 {
            width: 13px;
            height: 13px;
            background-color: #ff8d8d;
            border-radius: 3px;
          }
        }
        .flex-box-7 {
          @apply flex  items-center gap-2;
          .color-7 {
            width: 13px;
            height: 13px;
            background-color: #e695d4;
            border-radius: 3px;
          }
        }
      }
    }
  }
}

.income1 {
  @apply w-full p-4 rounded-lg;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .income-rent {
    @apply grid grid-cols-7 max-xl:grid-cols-4 max-sm:grid-cols-2 text-center xl:gap-3 gap-4 mt-8 text-white;
    .rent {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #6c5dd3;
    }
    .tent-deposi {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #e695d4;
    }
    .water {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #a0d7e8;
    }
    .month {
      @apply border-r-2 rounded-sm pr-2;
      .fiElectricityne {
        @apply p-2 rounded-lg;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: #ffce72;
      }
    }

    .Fine {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #898088;
    }
    .ExpensesPerMonth {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #6edcff;
    }
    .Overdue {
      @apply p-2 rounded-lg;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #ff8d8d;
    }
  }
}

.MuiChartsLegend-root,
.MuiChartsLegend-row {
  display: none;
}
